import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Helmet from 'react-helmet';
import useSiteMetadata from '../components/SiteMetadata';

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  heroImage,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="main about-page container-narrow">
      <section className="hero">
        {heroImage ? (
          <div className="image-wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: heroImage.src,
                alt: heroImage.alt,
              }}
            />
          </div>
        ) : null}
      </section>
      <section className="grid-container flex-start">
        <div className="col-sm-12 col-md-10 off-md-1">
           <h1 className="hero-text-title">
            {title}
          </h1>
          <PageContent
            className="content about"
            content={content}
          />
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  heroImage: PropTypes.object,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { title } = useSiteMetadata();
  const location = `${process.env.PAGE_DOMAIN}/about/`;

  return (
    <Layout pageType="DetailsPage">
      <Helmet title={`About | ${title}`} />
      <GatsbySeo
        canonical={location}
        openGraph={{
          title: `About | ${title}`,
          url: `${location}`,
        }}
      />
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        heroImage={post.frontmatter.heroImage}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
      ...heroImageWide
    }
  }
`;
